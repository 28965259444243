class InviteRequest {
  public hash: string;

  public password: string;

  public first_name: string;

  public last_name: string;

  toJson() {
    return {
      hash: this.hash,
      password: this.password,
      first_name: this.first_name,
      last_name: this.last_name,
    };
  }
}

export default InviteRequest;
